<div class="app-study-dispatch-message">
  <p *ngIf="isDispatchOpen">
    Este estudio, solicitado por el cliente <b>{{ dispatch.customer }}</b> tiene
    pendiente una revisión manual asignada al bufete
    <b>{{ dispatch.lawFirmName }}</b>
  </p>
  <p *ngIf="!isDispatchOpen">
    Este estudio, solicitado por el cliente <b>{{ dispatch.customer }}</b> el
    día <b>{{ dispatch.createdAt | date: "dd/MM/yyyy" }}</b> a las
    <b>{{ dispatch.createdAt | date: "HH:mm" }}</b> ha sido revisado manualmente
    por el bufete <b>{{ dispatch.lawFirmName }}</b> el dia
    <b>{{ dispatch.closedAt | date: "dd/MM/yyyy" }}</b> a las
    <b>{{ dispatch.closedAt | date: "HH:mm" }}</b> y ha sido marcado como
    <b>{{ displayApprovalState }}</b> y {{ displayObservations }}
  </p>
  <div *ngIf="failedRules?.length" class="failed-rules">
    <b>¿Por qué este estudio pasó a revisión manual?</b>
    <ul>
      <li *ngFor="let rule of failedRules">{{ rule }}</li>
    </ul>
  </div>
</div>
