<div class="app-studies-table">
  <table
    mat-table
    matSort
    [matSortDirection]="sortDirection"
    [matSortActive]="STUDY_TABLE_COLUMN.CREATED_AT"
    [dataSource]="studies"
    (matSortChange)="onSortChange($event)"
    class="table-container"
  >
    <ng-container [matColumnDef]="STUDY_TABLE_COLUMN.DISPATCH_ID">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element">
        {{ element.lastDispatch.id }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="STUDY_TABLE_COLUMN.CREATED_AT">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disableClear]="true"
      >
        Fecha
      </th>
      <td mat-cell *matCellDef="let element">
        <a mat-button [routerLink]="[studyPagePath, element.id]">
          {{ element.createdAt }}
        </a>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="STUDY_TABLE_COLUMN.CUSTOMER_NAME">
      <th mat-header-cell *matHeaderCellDef>Cliente</th>
      <td mat-cell *matCellDef="let element">
        {{ element.executive.customer.name }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="STUDY_TABLE_COLUMN.EXECUTIVE_USERNAME">
      <th mat-header-cell *matHeaderCellDef>Ejecutivo</th>
      <td mat-cell *matCellDef="let element">
        {{ element.executive.username }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="STUDY_TABLE_COLUMN.RUT">
      <th mat-header-cell *matHeaderCellDef>RUT</th>
      <td mat-cell *matCellDef="let element">
        <a mat-button [routerLink]="[studyPagePath, element.id]">
          {{ element.rut }}
        </a>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="STUDY_TABLE_COLUMN.BUSINESS_NAME">
      <th mat-header-cell *matHeaderCellDef>Razón Social</th>
      <td mat-cell *matCellDef="let element">{{ element.businessName }}</td>
    </ng-container>

    <ng-container [matColumnDef]="STUDY_TABLE_COLUMN.SGL_CODE">
      <th mat-header-cell *matHeaderCellDef>SGL</th>
      <td mat-cell *matCellDef="let element">
        {{ element.sglCode }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="STUDY_TABLE_COLUMN.LAWYER_NAME">
      <th mat-header-cell *matHeaderCellDef>Abogado</th>
      <td mat-cell *matCellDef="let element">
        {{ element.lastDispatch.lawFirmName }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="STUDY_TABLE_COLUMN.APPROVED_DISPLAY">
      <th mat-header-cell *matHeaderCellDef>Aprobación</th>
      <td mat-cell *matCellDef="let element">{{ element.approvedDisplay }}</td>
    </ng-container>

    <ng-container [matColumnDef]="STUDY_TABLE_COLUMN.STATUS_DISPLAY">
      <th mat-header-cell *matHeaderCellDef>Estado</th>
      <td mat-cell *matCellDef="let element">
        {{ element.statusDisplay }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="STUDY_TABLE_COLUMN.DISPATCH_STATUS_DISPLAY">
      <th mat-header-cell *matHeaderCellDef>Estado</th>
      <td mat-cell *matCellDef="let element">
        {{ element.lastDispatch.statusDisplay }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columns"
      [class.hidden]="loading"
    ></tr>
  </table>
  <div *ngIf="loading" class="loading-container">
    <mat-spinner [diameter]="60"></mat-spinner>
  </div>
</div>
