import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { FacultyItem, FacultyRestrictionRemove } from '../types';
import { FacultyOption, FacultyRestrictionReset } from './types';
import { RestrictionReset } from './faculties-selection-list-item/types';

@Component({
  selector: 'app-faculties-selection-list',
  templateUrl: './faculties-selection-list.component.html',
  styleUrl: './faculties-selection-list.component.scss',
})
export class FacultiesSelectionListComponent {
  @Input() control: FormArray<FacultyItem>;
  @Input() facultyOptions: FacultyOption[];
  @Output() addFacultyRestriction = new EventEmitter();
  @Output() removeFacultyRestriction =
    new EventEmitter<FacultyRestrictionRemove>();
  @Output() resetFacultyRestriction =
    new EventEmitter<FacultyRestrictionReset>();

  getFacultyControl(option: FacultyOption): FacultyItem {
    const facultyIndex = this.control.value.findIndex(
      (faculty) => faculty.facultyId === option.value,
    );

    return facultyIndex === -1 ? null : this.control.at(facultyIndex);
  }

  toggleFaculty(option: FacultyOption) {
    const facultyIndex = this.control.value.findIndex(
      (faculty) => faculty.facultyId === option.value,
    );

    if (facultyIndex === -1) {
      const newFormGroup = new FormGroup({
        facultyId: new FormControl(option.value),
        message: new FormControl(option.label),
        restriction: new FormArray([]),
      });

      newFormGroup.markAsDirty();
      this.control.markAsDirty();
      this.control.push(newFormGroup);
    } else {
      this.control.markAsDirty();
      this.control.removeAt(facultyIndex);
    }
  }

  selectAll() {
    this.facultyOptions.forEach((facultyOption) => {
      const control = this.getFacultyControl(facultyOption);

      if (!control) {
        this.control.markAsDirty();
        this.control.push(
          new FormGroup({
            facultyId: new FormControl(facultyOption.value),
            message: new FormControl(facultyOption.label),
            restriction: new FormArray([]),
          }),
        );
      }
    });
  }

  addRestriction(facultyIndex: number) {
    this.addFacultyRestriction.emit({ facultyIndex });
  }

  removeRestriction(restrictionIndex: number, facultyIndex: number) {
    this.removeFacultyRestriction.emit({ restrictionIndex, facultyIndex });
  }

  resetRestriction(
    { field, restrictionIndex }: RestrictionReset,
    facultyIndex: number,
  ) {
    this.resetFacultyRestriction.emit({
      field,
      restrictionIndex,
      facultyIndex,
    });
  }
}
